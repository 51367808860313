import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled/macro';
import { useMemo, useState, } from 'react';
import Portal from '@mui/material/Portal';
import isPropValid from '@emotion/is-prop-valid';
import RouterLink from '@shared/ui/navigation/RouterLink';
import CounterBadge from '@shared/ui/badges/CounterBadge';
import IconText from '@shared/ui/icons/IconText';
import { ContainerColumn, SurfaceColumn } from '@src/components/styled';
const SubItemWidth = 213;
const IconTextStyled = styled(IconText, { shouldForwardProp: propName => propName !== 'collapsed' })(props => ({
    '& .MuiTypography-root': {
        maxWidth: props.collapsed ? 0 : 163,
        transition: 'max-width 0.25s',
    },
    gap: props.theme.spacing_sizes.xs * 1.25,
    svg: {
        width: 20,
        height: 20,
    },
}));
const Container = styled(ContainerColumn)(() => ({
    height: 'auto',
}));
const ChildrenHoverContainer = styled(ContainerColumn, {
    shouldForwardProp: propName => propName !== 'hovered' && propName !== 'collapsed' &&
        propName !== 'xPosition' && propName !== 'yPosition',
})(props => ({
    display: props.hovered && props.collapsed ? 'flex' : 'none',
    width: 'auto',
    height: 'auto',
    position: 'absolute',
    left: props.xPosition,
    top: props.yPosition,
    zIndex: 2,
    paddingLeft: props.theme.spacing_sizes.s,
}));
const ChildrenOverlayContainer = styled(SurfaceColumn)(props => ({
    position: 'relative',
    width: 'auto',
    minWidth: 224,
    height: 'auto',
    padding: `${props.theme.spacing_sizes.xm}px ${props.theme.spacing_sizes.m}px
              ${props.theme.spacing_sizes.xm}px ${props.theme.spacing_sizes.xm}px`,
    boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.08)',
    boxSizing: 'border-box',
    borderRadius: 12,
    zIndex: 2,
}));
const defineSelectedStyles = (theme, selected) => (selected ? ({
    [`${IconTextStyled}`]: {
        color: theme.palette.primary.main,
    },
    backgroundColor: theme.customColors.badge.profileTabActive.surface,
    '&:hover': {
        backgroundColor: theme.customColors.badge.profileTabActive.surface,
    },
    '&:focus': {
        backgroundColor: theme.customColors.badge.profileTabActive.surface,
        boxShadow: '0px 0px 0px 3px rgba(62, 99, 221, 0.20)', // TODO: move color to the theme
    },
    '&:active': {
        backgroundColor: theme.customColors.badge.profileTabActive.surface,
        boxShadow: '0px 0px 0px 3px rgba(62, 99, 221, 0.20)', // TODO: move color to the theme
    },
}) : ({}));
const defineBackgroundColor = (theme, disabled = false, hovered = false) => {
    if (disabled) {
        return { backgroundColor: 'transparent' };
    }
    return {
        backgroundColor: hovered ? theme.customColors.menu.item.hover : 'transparent',
    };
};
const ItemContainer = styled(RouterLink, {
    shouldForwardProp: (propName) => isPropValid(propName),
})(props => (Object.assign(Object.assign(Object.assign({ display: 'flex', flexDirection: 'column', width: '100%', position: 'relative', boxSizing: 'border-box', cursor: props.disabled ? 'default' : 'pointer', height: 'auto', maxWidth: props.collapsed ? 40 : 213, maxHeight: 40, padding: 10, alignItems: 'start', justifyContent: 'space-between', borderRadius: 8, transition: 'max-width 0.25s', textDecoration: 'unset', color: props.disabled ? props.theme.palette.text.disabled : '#7E868C' }, defineBackgroundColor(props.theme, props.disabled, props.hovered)), { [`${IconTextStyled}`]: {
        color: props.disabled ? '#ABB4BB' : '#7E868C',
    }, '&:focus': {
        backgroundColor: props.theme.customColors.surface.surface,
        boxShadow: `0px 0px 0px 3px ${props.theme.customColors.surface.shadow}`,
    }, '&:active': {
        backgroundColor: props.disabled ? 'transparent' : props.theme.customColors.menu.item.active,
        boxShadow: `0px 0px 0px 3px ${props.disabled ? 'transparent' : props.theme.customColors.surface.shadow}`,
    } }), defineSelectedStyles(props.theme, props.selected))));
const ChildrenContainer = styled('ul', {
    shouldForwardProp: propName => isPropValid(propName),
})(props => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    maxWidth: props.collapsed ? 62 : SubItemWidth,
    boxSizing: 'border-box',
    height: props.collapsed ? props.collapsedHeight : props.height,
    margin: props.collapsed ? 0 : `${props.theme.spacing_sizes.s}px 0`,
    paddingLeft: props.theme.spacing_sizes.xm,
    overflow: 'hidden',
    opacity: props.collapsed ? 0 : 1,
    transition: 'height 0.25s, width 0.25s, opacity 0.25s',
}));
const CounterBadgeContainer = styled.div(props => ({
    position: 'absolute',
    right: '0%',
    maxWidth: props.collapsed ? 0 : 163,
    maxHeight: props.collapsed ? 0 : 26,
    opacity: props.collapsed ? 0 : 1,
    transition: 'max-width 0.25s, max-height 0.25s, opacity 0s',
    overflow: 'hidden',
    '& .MuiTypography-root': {
        padding: props.collapsed ? 0 : `${props.theme.spacing_sizes.xs * 0.5}px ${props.theme.spacing_sizes.s}px`,
    },
}));
const SidebarItemInner = ({ badge = undefined, disabled = undefined, collapsed = undefined, Icon = null, text, counterSize = undefined, }) => {
    const displayStatusBadge = collapsed && badge;
    const displayCounterBadge = !collapsed && badge;
    const StatusBadgeComponent = useMemo(() => {
        if (displayStatusBadge && !disabled) {
            return (_jsx(IconTextStyled, { collapsed: Boolean(collapsed), text: text, IconComponent: Icon }));
        }
        if (displayStatusBadge && disabled && text) {
            _jsx(IconTextStyled, { collapsed: Boolean(collapsed), text: text, IconComponent: Icon });
        }
        return null;
    }, [displayStatusBadge, disabled, Icon, collapsed, text]);
    const MainComponent = useMemo(() => (_jsx(IconTextStyled, { collapsed: Boolean(collapsed), text: text, IconComponent: Icon })), [collapsed, Icon, text]);
    const CounterBadgeComponent = useMemo(() => (_jsx(CounterBadgeContainer, { collapsed: Boolean(collapsed), children: badge && (_jsx(CounterBadge, { text: badge, backgroundColor: '#E6EDFE', size: counterSize })) })), [displayCounterBadge, counterSize, badge, collapsed]);
    return (_jsxs(_Fragment, { children: [MainComponent, CounterBadgeComponent, StatusBadgeComponent] }));
};
const SidebarItem = ({ 'aria-label': ariaLabel = undefined, href = '', Icon, text, elementOrder = 0, subItemCollapsedHeight = 0, subItemHeight = 'auto', collapsed = undefined, selected = undefined, children = undefined, hoverMenuItems = undefined, onClick = undefined, badge = undefined, counterSize = undefined, disabled = false, }) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const handleClick = (ev) => {
        ev.stopPropagation();
        if (onClick) {
            ev.preventDefault();
            onClick();
        }
    };
    // height of topbar(72) + top padding(24) + order * sidebarItem height
    const yPosition = 71 + 24 + elementOrder * 40;
    // leftPadding of sibarItems(15) + width of sidebarItem(40)
    const xPosition = 55;
    return (_jsxs(Container, { "aria-label": ariaLabel, onMouseOver: handleMouseEnter, onMouseOut: handleMouseLeave, onClick: handleClick, role: 'presentation', children: [_jsx(ItemContainer, { selected: selected, to: href, disabled: disabled, hovered: isHovered, collapsed: Boolean(collapsed), tabIndex: disabled ? -1 : 0, onClick: handleClick, children: _jsx(SidebarItemInner, { Icon: Icon, text: text, badge: badge, counterSize: counterSize, collapsed: collapsed, disabled: disabled }) }), children && (_jsx(ChildrenContainer, { collapsedHeight: subItemCollapsedHeight, height: subItemHeight, collapsed: collapsed, "aria-hidden": collapsed, children: children })), hoverMenuItems && (_jsx(Portal, { children: _jsx(ChildrenHoverContainer, { hovered: isHovered, collapsed: collapsed, xPosition: xPosition, yPosition: yPosition, children: _jsx(ChildrenOverlayContainer, { children: hoverMenuItems }) }) }))] }));
};
export default SidebarItem;
